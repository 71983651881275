<template>

  <div
      class="action-btn"
      style="text-decoration: none;" @click="toggleFavorite()"
  >
    <div v-if="isFavorite">
      <ion-icon name="heart"  ></ion-icon>
      <span > Remove Favorite </span>
    </div>
    <div v-if="!isFavorite">
      <ion-icon name="heart-outline" ></ion-icon>
      <span> Add Favorite </span>
    </div>



  </div>
</template>

<script>
import {defineComponent} from "vue";
import {addIcons} from "ionicons";
import {heart, heartOutline} from "ionicons/icons";
import {mapActions} from "vuex";

addIcons({"heart-outline": heartOutline, heart});
const config = require('@/config');

export default defineComponent({
  data() {
    return {
      isFavorite:false,
      activeId:null
    };
  },
  components: {},
  props: ["favId"],
  computed: {},
  methods: {
    ...mapActions("personalDB", ["connectPersonalDB",'getItem','getItems', 'saveItem']),
    async toggleFavorite(){
      let favorites = await this.getItem('favorites_' + config.conference);
      favorites = favorites || [];
      if (favorites.indexOf(this.activeId) === -1) {
        favorites.push(this.activeId);
        this.isFavorite = true;
        this.saveFavoriteList(favorites)
      }else{
        favorites.splice(favorites.indexOf(this.activeId), 1);
        this.isFavorite = false;
        this.saveFavoriteList(favorites);
      }
    },
    async checkFavorite(id){
      let favorites = await this.getItem('favorites_' + config.conference);
      favorites = favorites || [];
      if (favorites.indexOf(id) != -1) {
        this.activeId = id;
        this.isFavorite = true;
      }
    },
    saveFavoriteList(favorites){
      console.log('saveFav', favorites)
      this.saveItem({name:'favorites_' + config.conference,item: favorites})
    },
  },

  created() {
    console.log('FAV',this.favId)
    this.activeId = this.favId
    this.checkFavorite(this.favId)
  },
});
</script>
<style lang="scss">
.loader {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;

  ion-spinner {
    margin-right: 10px;
  }
}
</style>