<template>
    <div class="wcpt-session-list" style="justify-content:flex-start; margin:0">
    
        <div v-for="session in sessions" :key="session.id" >
           <a @click="showDisclaimer(session)"  v-if="!session.isHeader" class="wcpt-session-item" :style="{'border-color':  session.color + ' !important'}"  >

            <!--img class="picture" src="@/assets/icon/avatar.png" v-if="!session.chair[0] || !session.chair[0].vitae_picture">
            <img class="picture" :src="'https://fileserver-188ab.kxcdn.com/file/' + session.chair[0].vitae_picture.split('/')[4]" v-if="session.chair[0] && session.chair[0].vitae_picture"-->
            <div class="head">
              <div class="type">{{session.session_type_text}}</div>
              <div class="time" v-if="session.location && session.location.id != 'da7ad0c0-3ed1-4500-1302-080000000018'">{{moment(session.start_time).format('dddd HH:mm') }} - {{moment(session.end_time).format('HH:mm') }} (CEST) <span style="color: grey" v-if="session.location"><p >{{session.location.name}}</p></span>  </div>
              <!--div class="code" v-if="session.code">[{{session.code}}]</div-->
            </div>
            
            <div class="content">
              <div class="title">{{session.title}}</div>
              <div class="chair" v-if="session.chair.length > 0"><strong>Chair:</strong> <span v-for="chair in session.chair" :key="chair">{{chair.display_name}}</span></div>
              <div class="speaker" style="overflow: hidden; max-height:100px" v-if="session.speakers.length > 0"><strong>Speakers:</strong> <span v-for="speaker in session.speakers" :key="speaker">{{speaker.first_name}} {{speaker.last_name}}</span></div>
            </div>
            <div class="indicator" v-if="session.liveSessionActive">[ LIVE ]</div>
           </a>
          <div v-if="session.isHeader" class="item item-divider" style="width:100vw; padding-left:20px">
            <h2> {{ moment(session.start_time).format('HH:mm') }} CEST</h2>
          </div>
        </div>

      </div>
</template>
<script>
import moment from 'moment';
import {alertController} from "@ionic/vue";
import {mapActions} from "vuex";
export default {
  data(){
    return{
    }
  },
  props: ['sessions'],
  created(){
      this.moment = moment;
  },
  methods:{
    ...mapActions('googleanalytics', ['trackWithLabel']),
    async showDisclaimer(session) {
      if (session.disclaimer && session.disclaimer.length > 10) {
        const alert = await alertController
            .create({
              cssClass: 'my-custom-class',
              header: session.title,
              message: '<strong>' + session.disclaimer + '</strong>',
              buttons: [
                {
                  text: 'Decline',
                  role: 'cancel',
                  cssClass: 'secondary',
                  id: 'cancel-button',
                  handler: blah => {
                    console.log('Confirm Cancel:', blah)
                  },
                },
                {
                  text: 'Accept',
                  id: 'confirm-button',
                  handler: () => {
                    this.trackWithLabel(session.title);
                    this.$router.push('/app/sessions/' + session.id);
                  },
                },
              ],
            });
        return alert.present();
      } else {
        this.$router.push('/app/sessions/' + session.id);
      }

    }
  }
  
};
</script>